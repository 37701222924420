<template>
  <app-header />
  <router-view />
  <contact />
  <footer id="footer">
    <div class="copyright">
      &copy; Copyright <strong>{{ brandConfig.displayName }}</strong
      >. All Rights Reserved
    </div>
  </footer>
  <button
    :class="{ active: backToTop }"
    class="back-to-top"
    @click="handleLinkClick('header')"
  >
    <i class="fas fa-chevron-up"></i>
  </button>
  <notification
    :errorMessage="errorMessage"
    @clear-error-message="clearErrorMessage"
  />
</template>

<script>
import {
  defineComponent, onMounted, provide, ref,
} from 'vue';
import { find } from 'lodash';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import AppHeader from '@/components/Header.vue';
import Notification from '@/components/Notification.vue';
import brandConfig from '@/brand.config';
import playGame from '@/api/playGame';
import getUserAgent from '@/utils/userAgent';
import Contact from './components/Home/Contact.vue';

export default defineComponent({
  components: {
    AppHeader,
    Notification,
    Contact,
  },
  setup() {
    const $router = useRouter();
    const $i18n = useI18n({ useScope: 'global' });
    const devicePath = ref(null);
    const orientation = ref(null);
    const handleResize = () => {
      const deviceWidth = document.documentElement.clientWidth;
      if (deviceWidth > 1200) {
        devicePath.value = 'web';
      } else if (deviceWidth > 767) {
        devicePath.value = 'pad';
      } else {
        devicePath.value = 'app';
      }
      orientation.value = document.documentElement.clientWidth < document.documentElement.clientHeight ? 'portrait' : 'landscape';
    };
    const easeInOutQuad = (currentTime, currentScrollTop, displacement, duration) => {
      currentTime /= (duration / 2);
      if (currentTime < 1) return ((displacement / 2) * currentTime * currentTime) + currentScrollTop;
      currentTime -= 1;
      return (-displacement / 2) * (currentTime * (currentTime - 2) - 1) + currentScrollTop;
    };
    const handleLinkClick = (link) => {
      const element = document.getElementById(link);
      // safari 12以下版本需用其他方式取元件
      const userAgent = getUserAgent();
      const docBody = userAgent.browser === 'Safari' && userAgent.versionNumber < 13 ? document.body : document.documentElement;
      const currentScrollTop = docBody.scrollTop;
      const displacement = element.offsetTop - currentScrollTop; // 位移
      let currentTime = 0;
      const timeIncrement = 6;
      const animateScroll = () => {
        currentTime += timeIncrement; // timer
        const val = easeInOutQuad(currentTime, currentScrollTop, displacement, 300);
        docBody.scrollTop = val;
        if (currentTime < 300) {
          // 若時間未到duration繼續執行
          setTimeout(animateScroll, timeIncrement);
        }
      };
      animateScroll();
    };
    const backToTop = ref(null);
    const pageScrolled = () => {
      backToTop.value = window.scrollY > 100;
    };
    const goPage = ({ page, params = {} }) => {
      $router.push({ name: page, params });
    };
    onMounted(() => {
      handleResize();
      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', pageScrolled);
    });
    const errorMessage = ref(null);
    const clearErrorMessage = () => {
      errorMessage.value = null;
    };
    const handlePlayGame = async ({
      gameCode,
    }) => {
      const languageList = [{
        langId: 3,
        langName: 'en',
      }, {
        langId: 4,
        langName: 'th',
      }, {
        langId: 7,
        langName: 'vi',
      }];
      const match = find(languageList, (item) => item.langName === $i18n.locale.value);
      const newWindow = window.open();
      playGame({
        brand: brandConfig.brand,
        langId: match ? match.langId : 3, // en
        gameCode,
      }).then((data) => {
        newWindow.location.href = data.data.data;
      });
    };
    provide('brandConfig', brandConfig);
    provide('devicePath', devicePath);
    provide('orientation', orientation);
    provide('handleLinkClick', handleLinkClick);
    provide('goPage', goPage);
    provide('handlePlayGame', handlePlayGame);
    return {
      brandConfig,
      backToTop,
      handleLinkClick,
      errorMessage,
      clearErrorMessage,
    };
  },
  mounted() {
    this.$aos.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false,
    });
  },
});
</script>
