const number = (value) => {
  const aIntNum = value ? value.toString().split('.') : '0';
  let iIntPart = aIntNum[0];
  const iFlootPart = aIntNum.length > 1 ? `.${aIntNum[1]}` : '';
  const rgx = /(\d+)(\d{3})/;

  if (iIntPart.length >= 4) {
    while (rgx.test(iIntPart)) { iIntPart = iIntPart.replace(rgx, '$1,$2'); }
  }
  return iIntPart + iFlootPart;
};

export default {
  number,
};
