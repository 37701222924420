import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  }, {
    path: '/list',
    alias: ['/list/'],
    redirect: { name: 'List', params: { gameType: 'lottery' } },
  }, {
    path: '/list/:gameType',
    name: 'List',
    component: () => import('@/views/List.vue'),
  }, {
    path: '/detail/:gameName',
    name: 'Detail',
    component: () => import('@/views/Detail.vue'),
  }, {
    path: '/:other',
    redirect: { name: 'Home' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
