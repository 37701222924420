/* eslint-disable consistent-return */
import axios from 'axios';
import { isEmpty } from 'lodash';

const pending = [];
const removePending = (config) => {
  Object.keys(pending).forEach((key) => {
    if (pending[key]) {
      if (pending[key].url === config.url && pending[key].data === config.data) {
        pending.splice(key, 1);
      }
    }
  });
};

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 30000, // request timeout
});

// request攔截器
service.interceptors.request.use((config) => {
  if (config.data && config.data.specialCode) {
    config.specialCode = config.data.specialCode;
    delete config.data.specialCode;
  }
  return config;
});

// respone拦截器
service.interceptors.response.use(
  (response) => {
    removePending(response.config);
    return response.data;
  },
  async (err) => {
    const { response: error } = err;
    if (!error) return;
    removePending(error.config);

    const { code: errorCode } = error.data;

    // http server error
    if (isEmpty(errorCode)) {
      return Promise.resolve({ code: 'common.forbidden' });
    }

    // 以下都是GlsError
    return Promise.resolve(error.data);
  },
);

export default service;
