<template>
  <header id="header" class="fixed-top">
    <div id="logo">
      <button @click="goPage({ page: 'Home' })">
        <img :src="require(`@/assets/images/${brandConfig.brand}.png`)" />
      </button>
    </div>

    <div id="hamburger">
      <div
        class="menu-tab"
        :class="{ active: activeHamburger }"
        @click="handleToggleHamburger"
      >
        <span id="one"></span>
        <span id="two"></span>
        <span id="three"></span>
      </div>
      <div class="menu-hide" :class="{ show: activeHamburger }">
        <nav
          id="navbar"
          :class="{
            navbarWrap: devicePath !== 'web' && orientation === 'landscape',
          }"
        >
          <div class="navbar-container">
            <ul>
              <li
                v-for="(link, index) in menuList.slice(0, 3)"
                :key="index"
                @click="handleMenuClick(link)"
              >
                <sup>{{ padStart(index, 2, "0") }}</sup>
                {{ $t(`common.menuList.${link}`) }}
              </li>
            </ul>
            <ul>
              <li
                v-for="(link, index) in menuList.slice(3)"
                :key="index"
                @click="handleMenuClick(link)"
              >
                <sup>{{ padStart(index + 3, 2, "0") }}</sup>
                {{ $t(`common.menuList.${link}`) }}
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>

    <div id="lang-list">
      <button
        @click="handleToggleLanguageList"
        :class="activeLanguageList ? 'lang-active' : 'collapsed lang-active'"
      >
        <span class="lang">{{
          devicePath === "app"
            ? $t(`common.language`)
            : $t(`common.lang.${languageSelected}`)
        }}</span>
        <i :class="`fas fa-chevron-${activeLanguageList ? 'up' : 'down'}`"></i>
      </button>
      <div id="lang" v-if="activeLanguageList">
        <ul>
          <li
            v-for="item in brandConfig.languageList"
            :key="item"
            :class="{ active: languageSelected === item }"
            @click="handleChangeLanguage(item)"
          >
            {{ $t(`common.lang.${item}`) }}
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import {
  defineComponent, ref, onMounted, inject,
} from 'vue';
import { useRouter } from 'vue-router';
import { padStart } from 'lodash';
import { getLanguage, setLanguage } from '@/utils/auth';

export default defineComponent({
  name: 'Header',
  setup() {
    const $router = useRouter();
    const brandConfig = inject('brandConfig');
    const devicePath = inject('devicePath');
    const orientation = inject('orientation');
    const handleLinkClick = inject('handleLinkClick');
    const goPage = inject('goPage');
    const activeLanguageList = ref(false);
    const activeHamburger = ref(false);
    const languageSelected = ref(getLanguage());
    const menuList = ['about', 'gameTypes', 'ourService', 'partnership', 'contact'];
    const handleToggleLanguageList = () => {
      activeLanguageList.value = !activeLanguageList.value;
    };
    const handleToggleHamburger = () => {
      activeHamburger.value = !activeHamburger.value;
    };
    const headerScrolled = () => {
      const selectHeader = document.querySelector('#header');
      if (window.scrollY > 100) {
        selectHeader.classList.add('header-scrolled');
      } else {
        selectHeader.classList.remove('header-scrolled');
      }
    };
    const handleMenuClick = (link) => {
      handleToggleHamburger();
      const currentPage = $router.currentRoute.value.name;
      if (currentPage === 'Home' || link === 'contact') {
        handleLinkClick(link);
      } else {
        goPage({ page: 'Home', params: { link } });
      }
    };
    onMounted(() => {
      window.addEventListener('scroll', headerScrolled);
    });
    return {
      brandConfig,
      padStart,
      menuList,
      activeLanguageList,
      activeHamburger,
      languageSelected,
      handleToggleLanguageList,
      handleToggleHamburger,
      handleMenuClick,
      devicePath,
      orientation,
      goPage,
    };
  },
  methods: {
    handleChangeLanguage(language) {
      this.languageSelected = language;
      this.$i18n.locale = language;
      setLanguage(this.languageSelected);
      this.handleToggleLanguageList();
    },
  },
});
</script>

<style lang="scss" scoped>
#header {
  background: rgba(0, 0, 0, 0.75);
  padding: 15px 0;
  transition: all 0.5s;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    padding: 10px 0;
  }
  #logo {
    font-size: 0;
    img {
      height: 100px;
      transition: all 0.5s;
      @media (max-width: 480px) {
        max-height: 40px;
      }
    }
  }
  &.header-scrolled {
    background: #000;
    padding: 10px 0;
    transition: all 0.5s;
    #logo img {
      height: 70px;
    }
  }
  #lang-list {
    position: absolute;
    right: 0;
    padding: 0 10px;
    .lang-active {
      cursor: pointer;
      color: #fff;
      line-height: 30px;
      width: 120px;
      text-align: right;
      i {
        display: inline-block;
        transition: 0.6s;
        padding: 0 10%;
        @media (max-width: 1200px) {
          padding: 0 2%;
        }
      }
    }
    #lang {
      position: absolute;
      min-width: 104px;
      width: auto;
      right: 12px;
      top: 40px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      background: #fff;
      box-shadow: 0 0 10px 5px rgb(0 0 0 / 8%);
      border-radius: 5px;
      overflow: hidden;
      li {
        display: block;
        padding: 0 10px;
        color: #999;
        line-height: 40px;
        white-space: nowrap;
        transition: 0.3s;
        cursor: pointer;
        &:hover {
          background: #f1f1f1;
        }
        &.active {
          color: #000;
        }
      }
      li:not(:last-child) {
        border-bottom: 1px solid #ccc;
      }
    }
  }
  #hamburger {
    position: absolute;
    left: 10px;
    @media (max-width: 480px) {
      left: 0;
    }
    .menu-tab {
      position: relative;
      z-index: 11;
      cursor: pointer;
      transition: all 0.6s ease-in-out;
      span {
        width: 35px;
        height: 4px;
        background: #fff;
        display: block;
        margin: 5px 10px;
        transition: all 0.6s ease-in-out;
      }
      &.active {
        transition: all 600ms ease-in-out;
        #one {
          transform: translateY(9px) rotate(-135deg);
          transition: all 0.6s ease-in-out;
        }
        #two {
          opacity: 0;
          transition: 0.4s ease;
        }
        #three {
          transform: translateY(-9px) rotate(-45deg);
          transition: all 0.6s ease-in-out;
        }
      }
    }
    .menu-hide {
      width: 100%;
      left: -100%;
      height: 100vh;
      position: fixed;
      z-index: 10;
      top: 0;
      opacity: 0;
      transition: all 0.6s ease-in-out;
      @media (max-width: 991px) {
        width: 100%;
        left: -100%;
      }
      &.show {
        opacity: 1;
        left: 0;
        background: rgba(26, 26, 26, 0.9);
        transition: all 0.6s ease-in-out;
      }
      nav {
        .navbar-container {
          margin: 0;
          padding: 0;
          list-style: none;
          width: 50%;
          height: 100vh;
          box-sizing: border-box;
          padding-top: 90px;
          background: rgba(204, 0, 255, 0.75);
          transition: all 0.6s ease-in-out;
          @media (max-width: 991px) {
            width: 100%;
          }
          @media (max-width: 480px) {
            padding-top: 50px;
          }
          ul {
            margin: 0;
            li {
              padding: 0 10%;
              position: relative;
              display: block;
              font-family: Montserrat, sans-serif;
              color: rgba(255, 255, 255, 0.5);
              font-size: 32px;
              padding: 30px;
              transition: all 0.5s ease;
              @media (max-width: 480px) {
                font-size: 28px;
                padding: 20px;
              }
              &:hover {
                color: #fff;
              }
              &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 5px;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                background: #fff;
                visibility: hidden;
                transform: scaleX(0);
                transition: all 0.3s ease-in-out 0s;
              }
              &:hover:before {
                visibility: visible;
                transform: scaleX(1);
              }
              sup {
                top: -1em;
                font-size: 0.5em;
                color: #fff;
              }
            }
          }
        }
        &.navbarWrap {
          @media (max-width: 991px) {
            .navbar-container {
              display: flex;
              flex-wrap: wrap;
              ul {
                width: 50%;
                padding: 0;
                li {
                  padding: 20px 0 20px 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
