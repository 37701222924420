import request from '@/utils/request';

const playGame = ({
  brand,
  langId,
  gameCode,
}) => request.post('/official/playGame', {
  brand,
  langId,
  gameCode,
});

export default playGame;
