class GlsError extends Error {
  constructor({
    code = 'UnknownError', data = {},
  }) {
    super();
    this.name = 'GlsError';
    this.code = code;
    this.data = data;
  }
}

export default GlsError;
