const getUserAgent = () => {
  const { userAgent } = navigator;
  let browser;
  let versionNumber;
  if (userAgent.indexOf('Opera') !== -1) {
    browser = 'Opera';
    let versionOffset = userAgent.indexOf('Opera');
    versionNumber = userAgent.substring(versionOffset + 6);
    if (userAgent.indexOf('Version') !== -1) {
      versionOffset = userAgent.indexOf('Version');
      versionNumber = userAgent.substring(versionOffset + 8);
    }
  } else if (userAgent.indexOf('Chrome') !== -1) {
    browser = 'Chrome';
    const versionOffset = userAgent.indexOf('Chrome');
    versionNumber = userAgent.substring(versionOffset + 7);
  } else if (userAgent.indexOf('Safari') !== -1) {
    browser = 'Safari';
    let versionOffset = userAgent.indexOf('Safari');
    versionNumber = userAgent.substring(versionOffset + 7);
    if (userAgent.indexOf('Version') !== -1) {
      versionOffset = userAgent.indexOf('Version');
      versionNumber = userAgent.substring(versionOffset + 8);
    }
  } else if (userAgent.indexOf('Firefox') !== -1) {
    browser = 'Firefox';
    const versionOffset = userAgent.indexOf('Firefox');
    versionNumber = userAgent.substring(versionOffset + 8);
  } else {
    const nameOffset = userAgent.lastIndexOf(' ') + 1;
    const versionOffset = userAgent.lastIndexOf('/');
    browser = userAgent.substring(nameOffset, versionOffset);
    versionNumber = userAgent.substring(versionOffset + 1);
  }
  return {
    browser,
    versionNumber,
  };
};

export default getUserAgent;
