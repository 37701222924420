/* eslint-disable import/no-extraneous-dependencies */
import { createApp } from 'vue';
import aos from 'aos';
import router from '@/router';
import GlsError from '@/utils/error';
import i18n from '@/utils/i18n';
import filters from '@/filters';
import App from '@/App.vue';

import 'normalize.css/normalize.css'; // A modern alternative to CSS resets
import '@fortawesome/fontawesome-free/css/all.css'; // fontawesome css
import 'aos/dist/aos.css'; // aos animation css
import '@/styles/index.scss'; // global css

const app = createApp(App)
  .use(i18n)
  .use(router)
  .use(aos);

app.config.globalProperties.$aos = aos;
app.config.globalProperties.$t = i18n.global.t;
app.config.globalProperties.$filters = filters;

app.config.errorHandler = (err, vm) => {
  if (err instanceof GlsError) {
    vm.loading = false;
  } else {
    throw err;
  }
};

app.mount('#app');
