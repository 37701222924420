<template>
  <div id="notification" v-if="showModal" data-aos="fade-left">
    <i class="icon fas fa-exclamation-circle"></i>
    <p class="message">{{ $t(`common.error.${message}`) }}</p>
    <button class="close-button" @click="closeModal">
      <i class="fa fa-times"></i>
    </button>
  </div>
</template>

<script>
import {
  defineComponent, ref, computed, watch,
} from 'vue';

export default defineComponent({
  props: {
    errorMessage: {
      type: String,
      default: () => null,
    },
  },
  emits: ['clear-error-message'],
  setup(props, { emit }) {
    const showModal = ref(false);
    const closeModal = () => {
      showModal.value = false;
      emit('clear-error-message');
    };
    const message = computed(() => props.errorMessage);
    watch(message, (val) => {
      if (val) {
        showModal.value = true;
        setTimeout(() => {
          closeModal();
        }, 5000);
      }
    });
    return {
      message,
      showModal,
      closeModal,
    };
  },
});
</script>

<style lang="scss" scoped>
#notification {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  position: fixed;
  z-index: 1000;
  top: 5%;
  right: 0.5%;
  max-width: 70%;
  .icon {
    font-size: 1.2rem;
    color: #f56c6c;
  }
  .message {
    margin: 0 10px;
  }
  .close-button {
    font-size: 1.2rem;
    color: #666666;
  }
}
</style>
