<template>
  <section id="contact">
    <div class="container" data-aos="fade-up">
      <div class="section-header">
        <h3 class="section-title">{{ $t("common.menuList.contact") }}</h3>
      </div>

      <div class="social-links">
        <button
          v-for="(item, index) in brandConfig.contactList"
          :key="index"
          @click="handleClickContact(item)"
        >
          <i :class="`fab fa-${item.type}`"></i>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, inject } from 'vue';

export default defineComponent({
  setup() {
    const brandConfig = inject('brandConfig');
    const handleClickContact = (item) => {
      window.location = item.link;
    };
    return {
      brandConfig,
      handleClickContact,
    };
  },
});
</script>

<style lang="scss" scoped>
#contact {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 60px 0;
  overflow: hidden;
  background: #000;
  .section-header .section-title {
    color: #fff;
  }
  .social-links {
    text-align: center;
    button {
      font-size: 50px;
      color: #fff;
      margin: 0 20px;
      transition: 0.3s;
      display: inline-flex;
      width: 50px;
      height: 50px;
      &:hover {
        color: #cc00ff;
      }
    }
  }
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
