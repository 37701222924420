module.exports = {
  brand: 'APLUS',
  displayName: 'APLUS',
  gameLanguageList: ['en', 'vi'],
  currencyList: ['BDT', 'IDR', 'INR', 'MMK', 'MXN', 'MYR', 'VND', 'USD', 'PHP'],
  languageList: ['en', 'th', 'zhHans'],
  gameList: [
    { gameCode: '1-0001', gameName: 'ThailandLotto' },
    { gameCode: '1-0002', gameName: 'Vietnam(Hanoi)Lotto' },
    { gameCode: '1-0003', gameName: 'LaosLotto' },
    { gameCode: '1-0005', gameName: 'Malaysia(Magnum4D)Lotto' },
    { gameCode: '1-0006', gameName: 'Singapore(4D)Lotto' },
    { gameCode: '1-0009', gameName: 'Thailand(SET)Stock' },
    { gameCode: '1-0010', gameName: 'India(Sensex)Stock' },
    { gameCode: '1-0012', gameName: 'Japan(Nikkei225)Stock' },
    { gameCode: '1-0013', gameName: 'Korea(KTOP30)Stock' },
    { gameCode: '1-0014', gameName: 'Vietnam(HoChiMinh)Lotto' },
    { gameCode: '1-0015', gameName: 'Vietnam(DaNang)Lotto' },
    { gameCode: '1-0016', gameName: 'Vietnam(KhanhHoa)Lotto' },
    { gameCode: '2-0003', gameName: 'Zodiac' },
    { gameCode: '2-0007', gameName: 'WinWin' },
    { gameCode: '2-0008', gameName: 'Keno' },
  ],
  contactList: [{
    type: 'google',
    link: 'mailto:salesocms@gmail.com',
  }, {
    type: 'skype',
    link: 'skype:live:.cid.628bcff5cf852f?chat',
  }],
};
